<template>
  <div class="content">
      <div class="SearchBar">
        <el-button  @click="closeadd" size="mini" class="backbtn">返回</el-button>
        <el-form :inline="true" ref="templateForm" :model="templateForm" :rules="rule">
          <el-row>
            <el-col class="cow">
              <el-form-item label="模板名称" label-width="163px" class="searchItem" prop="tplName">
                <el-input
                  v-model="templateForm.tplName"
                  placeholder="请输入"
                  maxlength="30"
                  clearable
                  style="width:623px;height:40px"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col class="cow">
              <el-form-item label="分类" label-width="163px" class="searchItem" prop="repairType">
                <!-- <el-select v-model="templateForm.tempType" placeholder="请选择"  clearable  style="width:434px;height:40px">
                  <el-option
                    v-for="item in options"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                  </el-option>
                </el-select> -->
                <el-cascader
                  style="width:623px;height:40px"
                  :options="options"
                  ref="cascader"
                  v-model="templateForm.repairType"
                  :props="{  label:'name' , value:'code' ,emitPath:false }"
                  separator='>'
                  clearable
                  collapse-tags
                  @change="deformation">
                </el-cascader>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col class="cow">
              <el-form-item label="启用状态" label-width="163px" class="searchItem" prop="generalStatus">
                <template>
                  <el-radio-group v-model="templateForm.generalStatus">
                  <el-radio  :label="1">启用</el-radio>
                  <el-radio  :label="0">禁用</el-radio>
                  </el-radio-group>
                </template>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col class="cow">
              <el-form-item label="采集项" label-width="163px" class="searchItem" prop="itemIds">
                <!-- <template> -->
                  <div class="checkDiv">
                    <div  class="checkout_box">
                      <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
                      <div>({{templateForm.itemIds.length}}/{{citiesList.length}})</div>
                    </div>
                    <el-checkbox-group v-model="templateForm.itemIds" @change="handleCheckedCitiesChange">
                      <el-checkbox v-for="city in citiesList" :label="city.id" :key="city.id">{{city.itemName}}</el-checkbox>
                    </el-checkbox-group>
                  </div>
                <!-- </template> -->
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div class="btndiv">
          <el-button  @click="closeadd">取消</el-button>
          <el-button  @click="addUpdata" type="primary" style="margin: 27px 50px 41px 20px;">确定</el-button>
        </div>
      </div>
    
  </div>
</template>
<script>
import {
  dicByType,
} from "@/RequestPort/maintenance";
import { templateInsertOrUpdate,templateItemList,templateDetails,findDictTreeByCode} from "@/RequestPort/serviceOrders";
export default {
  name: 'addCollec',
  data() {
    return {
      // 用户分类
      options:[],
      // 对象
      templateForm:{
        generalStatus:1,
        itemIds: [],
        tempType:''
      },
      // 全选
      checkAll: false,
      // 所有采集项
      citiesList: [],
      isIndeterminate: false,
      rule:{
        tplName: {
          required: true,
          message: "请填写模板名称",
          trigger: "blur",
        },
        repairType: {
          required: true,
          message: "请选择分类",
          trigger: "change",
        },
        generalStatus: {
          required: true,
          message: "请选择启用状态",
          trigger: "change",
        },
        itemIds: {
          required: true,
          message: "请选择采集项",
          trigger: "change",
        },
      },
      // 事件类型
      repairType:[]
    }
  },
  mounted() {
    let that = this
    // 获取事件
    this.showEventTypeList()
    // 所有采集项
    templateItemList().then(res => {
      this.citiesList = res.data
    })
    if(this.$route.query.id){
      this.particulars(this.$route.query.id)
    }
  },
  methods: {
    /**
     * 事件类型 选择事件
     */
    deformation(val){
      this.templateForm.repairType = val
    },
     /**
     * 获取事件类型
     */
    showEventTypeList(){
      // 获取分类字典
      findDictTreeByCode({dictCode:'ywgd,GAS_PATROL_EVENT',excludeCode:'yichangbaoxiu1'}).then(res => {
        this.options = res.data
        this.childrenData(this.options)
      })
    },
    childrenData(val) {
      val.forEach(el => {
        if(el.children && el.children.length>0){
          this.childrenData(el.children)
        } else if(el.value == 1) {
          el.disabled=true
        }
      });
    },
    //返回
    closeadd() {
      this.$router.push({ path: '/home/dicManage', query: { first: 2, screcd: 2 } })
    },
    /**
     * 获取模板详细信息
     */
    particulars(id) {
      templateDetails({id}).then(res =>{
        // this.form = res.data
        let itemIds = []

        if(res.data.disItemVOList && res.data.disItemVOList.length>0){
          res.data.disItemVOList.forEach(el => {
            itemIds.push(el.id)
          })
        }
        res.data = {... res.data,itemIds}
        this.templateForm ={...this.templateForm,...res.data}
      //  this.templateForm.eventTypeIdList = this.templateForm.repairType
        this.handleCheckedCitiesChange(itemIds)
      })
    },
    /**
     * 全选
     */
    handleCheckAllChange(val) {
      let cityOptions  = []
      this.citiesList.forEach(el => {
        cityOptions.push(el.id)
      })
      this.templateForm.itemIds = val ? cityOptions : [];
      this.isIndeterminate = false;
    },
    /**
     * 单选
     */
    handleCheckedCitiesChange(value) {
      this.$forceUpdate()
      this.$nextTick(() => {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.citiesList.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.citiesList.length;
      })
    },
    /**
     * 确认/编辑
     */
    addUpdata(){
      this.$refs["templateForm"].validate((valid) => {
        if (valid) {
          templateInsertOrUpdate(this.templateForm).then(res => {
            this.$message.success(res.data);
            this.$router.push({ path: '/home/dicManage', query: { first: 2, screcd: 2 } })
          })
        } else {
          this.$message.error('有数据未填写，请仔细核对')
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.searchItem {
  margin-bottom: 34px;
}
.label {
  color: #909399;
}
.SearchBar {
  border-radius: 5px;
  margin: 10px;
  background-color: #ffffff;
}
// 复选框顶部内容
.checkout_box{
    border-bottom: 1px solid rgb(220, 223, 230);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
// 复选框换行
.el-checkbox{
  display: block;
}
.table2{
  width: 1570px;
  overflow: hidden;
  border: 1px solid #EBEBEB;
  tr{
    height: 50px;
  }
  td{
    border: 1px solid #EBEBEB;
    padding: 5px;
  }
  th{
    padding-top: 12px;
    border: 1px solid #EBEBEB;
    background-color: #F9FAFE;
  }
}
.addbtn{
  width: 92px;
  height: 38px;
  float: right;
  margin-right: -40px;
}
.backbtn{
  margin:30px 0px 40px 30px;
  width: 82px;
  height: 36px;
}
.btndiv{
  margin-left: 163px;
}
.content {
  background: #f3f4f8;;
  width: 100%;
  border-radius: 6px;
  overflow:auto;
  height: calc(100vh - 150px);
}
.delbtn{
  float: right;
  margin-right: 21px;
}
.checkDiv{
  padding: 2%;
  border: 1px solid #DCDFE6;
  width: 600px;
}
/deep/.el-checkbox__label{
  white-space: pre-wrap;
}
</style>
